import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Wait for the next tick to ensure DOM is updated
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant' // Changed from 'smooth' to 'instant' for immediate scroll
      });
    }, 0);
  }, [pathname]);

  return null;
};