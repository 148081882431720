import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  FileText, 
  Video, 
  Play, 
  Download, 
  Globe, 
  FileCheck,
  File,
  Search,
  Tag,
  CheckCircle
} from 'lucide-react';
import { SEO } from '../components/SEO';

const Resources = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'success' | 'error' | null>(null);

  const handleNewsletterSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    const form = e.currentTarget;
    const formData = new FormData(form);

    try {
      const response = await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData as any).toString()
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setSubmitStatus('success');
      form.reset();
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Filter resources based on search and category
  const filterResources = (items: any[], type: string) => {
    return items.filter(item => {
      const matchesSearch = 
        item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.description.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesCategory = !selectedCategory || item.category === selectedCategory;
      return matchesSearch && matchesCategory;
    });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <SEO
        title="IT Resources & Insights | NIT Solutions Calgary"
        description="Access our library of IT resources, whitepapers, guides, and insights to help you make informed technology decisions for your business."
        keywords="IT resources, technology guides, IT whitepapers, cybersecurity resources, cloud computing guides, IT best practices"
        path="/resources"
      />
      
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-blue-600 to-blue-800 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            IT Resources & Insights
          </h1>
          <p className="text-xl text-white/90 mb-8 max-w-3xl mx-auto">
            Access our library of resources to help you make informed IT decisions
            and stay up-to-date with the latest technology trends.
          </p>

          {/* Search Bar */}
          <div className="max-w-2xl mx-auto relative">
            <div className="relative">
              <Search className="absolute left-4 top-3.5 h-5 w-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search resources..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-12 pr-4 py-3 rounded-lg bg-white/10 text-white placeholder-white/60 backdrop-blur-sm border border-white/20 focus:outline-none focus:ring-2 focus:ring-white/50"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Category Filters */}
      <section className="py-8 bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-wrap gap-4 justify-center">
            {categories.map((category) => (
              <button
                key={category.name}
                onClick={() => setSelectedCategory(category.name === selectedCategory ? null : category.name)}
                className={`flex items-center px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                  category.name === selectedCategory
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
              >
                <Tag className="w-4 h-4 mr-2" />
                {category.label}
              </button>
            ))}
          </div>
        </div>
      </section>

      {/* Resource Categories */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Videos Section */}
          <div className="mb-16">
            <h2 className="text-3xl font-bold mb-8">Video Resources</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {filterResources(videos, 'video').map((video, index) => (
                <ResourceCard
                  key={index}
                  resource={video}
                  type="video"
                />
              ))}
            </div>
          </div>

          {/* Whitepapers Section */}
          <div className="mb-16">
            <h2 className="text-3xl font-bold mb-8">Whitepapers & Guides</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {filterResources(whitepapers, 'whitepaper').map((paper, index) => (
                <ResourceCard
                  key={index}
                  resource={paper}
                  type="pdf"
                />
              ))}
            </div>
          </div>

          {/* Templates Section */}
          <div className="mb-16">
            <h2 className="text-3xl font-bold mb-8">Templates</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {filterResources(templates, 'template').map((template, index) => (
                <ResourceCard
                  key={index}
                  resource={template}
                  type="template"
                />
              ))}
            </div>
          </div>

          {/* Articles Section */}
          <div className="mb-16">
            <h2 className="text-3xl font-bold mb-8">Latest Articles</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {filterResources(articles, 'article').map((article, index) => (
                <ResourceCard
                  key={index}
                  resource={article}
                  type="article"
                />
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Tools Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold mb-8">Tools & Resources</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filterResources(tools, 'tool').map((tool, index) => (
              <ResourceCard
                key={index}
                resource={tool}
                type="template"
              />
            ))}
          </div>
        </div>
      </section>

      {/* Newsletter Signup */}
      <section className="bg-blue-600 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white mb-4">
            Stay Updated with IT Insights
          </h2>
          <p className="text-xl text-white/90 mb-8 max-w-3xl mx-auto">
            Subscribe to our newsletter for the latest IT news, security alerts, and expert tips.
          </p>
          <form 
            className="max-w-md mx-auto"
            name="newsletter"
            method="POST"
            onSubmit={handleNewsletterSubmit}
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="newsletter" />
            <p className="hidden">
              <label>
                Don't fill this out if you're human: <input name="bot-field" />
              </label>
            </p>
            <div className="flex gap-4">
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                className="flex-1 px-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                required
              />
              <button
                type="submit"
                disabled={isSubmitting}
                className={`bg-white text-blue-600 px-6 py-3 rounded-lg font-semibold transition duration-300 ${
                  isSubmitting ? 'opacity-75 cursor-not-allowed' : 'hover:bg-gray-100'
                }`}
              >
                {isSubmitting ? 'Subscribing...' : 'Subscribe'}
              </button>
            </div>
            {submitStatus === 'success' && (
              <p className="mt-4 text-green-100 bg-green-600/20 backdrop-blur-sm px-4 py-2 rounded-lg">
                Thank you for subscribing! You'll receive our newsletter soon.
              </p>
            )}
            {submitStatus === 'error' && (
              <p className="mt-4 text-red-100 bg-red-600/20 backdrop-blur-sm px-4 py-2 rounded-lg">
                There was an error subscribing. Please try again.
              </p>
            )}
          </form>
        </div>
      </section>
    </div>
  );
};

interface ResourceCardProps {
  resource: any;
  type: 'pdf' | 'template' | 'video' | 'article';
}

const ResourceCard: React.FC<ResourceCardProps> = ({ resource, type }) => {
  const [imageError, setImageError] = useState(false);

  const getIcon = () => {
    switch (type) {
      case 'pdf':
        return <FileText className="w-6 h-6 text-blue-600" />;
      case 'template':
        return <FileCheck className="w-6 h-6 text-green-600" />;
      case 'video':
        return <Video className="w-6 h-6 text-purple-600" />;
      case 'article':
        return <Globe className="w-6 h-6 text-orange-600" />;
      default:
        return <File className="w-6 h-6 text-gray-600" />;
    }
  };

  const getThumbnail = (type: string) => {
    switch (type) {
      case 'pdf':
        return 'https://images.unsplash.com/photo-1568667256549-094345857637?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80';
      case 'template':
        return 'https://images.unsplash.com/photo-1484480974693-6ca0a78fb36b?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80';
      case 'article':
        return 'https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80';
      default:
        return null;
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow">
      {(resource.thumbnail || getThumbnail(type)) && !imageError ? (
        <div className="aspect-w-16 aspect-h-9 relative group">
          <img
            src={resource.thumbnail || getThumbnail(type)}
            alt={resource.title}
            className="object-cover w-full h-48 md:h-56"
            onError={() => setImageError(true)}
          />
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity">
            {type === 'video' ? (
              <Play className="w-16 h-16 text-white" />
            ) : (
              getIcon()
            )}
          </div>
        </div>
      ) : null}
      <div className="p-6">
        <div className="flex items-center mb-4">
          {getIcon()}
          <h3 className="text-xl font-semibold ml-3">{resource.title}</h3>
        </div>
        <p className="text-gray-600 mb-4">{resource.description}</p>
        <div className="flex items-center justify-between">
          {type === 'video' && <span className="text-sm text-gray-500">{resource.duration || 'Video'}</span>}
          {type === 'pdf' && <span className="text-sm text-gray-500">PDF Document</span>}
          {type === 'template' && <span className="text-sm text-gray-500">Template</span>}
          {type === 'article' && <span className="text-sm text-gray-500">Article</span>}
          <a
            href={resource.link}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center text-blue-600 hover:text-blue-700"
          >
            {type === 'video' ? (
              <>
                <Play className="w-4 h-4 mr-2" />
                Watch Video
              </>
            ) : type === 'pdf' ? (
              <>
                <Download className="w-4 h-4 mr-2" />
                Download PDF
              </>
            ) : type === 'template' ? (
              <>
                <CheckCircle className="w-4 h-4 mr-2" />
                Access Template
              </>
            ) : (
              <>
                <Globe className="w-4 h-4 mr-2" />
                Read More
              </>
            )}
          </a>
        </div>
      </div>
    </div>
  );
};

const categories = [
  { name: 'cybersecurity', label: 'Cybersecurity' },
  { name: 'cloud', label: 'Cloud Computing' },
  { name: 'infrastructure', label: 'IT Infrastructure' },
  { name: 'business', label: 'Business IT' },
  { name: 'security', label: 'Security Updates' }
];

const videos = [
  {
    title: "Cybersecurity Basics for SMBs",
    description: "Learn the core principles of protecting your business from cyber threats, including phishing and malware prevention.",
    category: 'cybersecurity',
    duration: "45 min",
    thumbnail: "https://img.youtube.com/vi/0ER4p4C59xg/maxresdefault.jpg",
    link: "https://www.youtube.com/watch?v=0ER4p4C59xg"
  },
  {
    title: "Cloud Solutions for Small Businesses",
    description: "A comprehensive demo on how cloud solutions can optimize business operations and improve security.",
    category: 'cloud',
    duration: "32 min",
    thumbnail: "https://img.youtube.com/vi/cz5q5fhvjkc/maxresdefault.jpg",
    link: "https://www.youtube.com/watch?v=cz5q5fhvjkc"
  },
  {
    title: "Disaster Recovery and Backup Planning",
    description: "An introduction to creating an effective IT disaster recovery plan for small businesses.",
    category: 'infrastructure',
    duration: "28 min",
    thumbnail: "https://img.youtube.com/vi/b1qLc7sZiNw/maxresdefault.jpg",
    link: "https://www.youtube.com/watch?v=b1qLc7sZiNw"
  }
];

const whitepapers = [
  {
    title: "Small Business IT Security Best Practices",
    description: "A comprehensive guide on essential security measures for SMBs, including firewalls, backups, and password policies.",
    category: 'cybersecurity',
    thumbnail: "https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://www.cisa.gov/sites/default/files/2024-12/Top-Cybersecurity-Best-Practices-Basic-Final-508.pdf"
  },
  {
    title: "IT Disaster Recovery Planning Template",
    description: "A step-by-step template to prepare your business for unexpected disasters, minimizing downtime and data loss.",
    category: 'infrastructure',
    thumbnail: "https://images.unsplash.com/photo-1504639725590-34d0984388bd?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://www.disasterrecoveryplantemplate.org/download/disaster-recovery-plan-template-basic/"
  },
  {
    title: "Cloud Migration Guide for Small Businesses",
    description: "Learn how to plan, budget, and execute a successful cloud migration for your SMB.",
    category: 'cloud',
    thumbnail: "https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://cdn2.hubspot.net/hubfs/3857897/Complete%20Cloud%20Migration%20Guide%20v2.1.pdf"
  },
  {
    title: "Cybersecurity for Remote Work",
    description: "A guide to securing your IT environment while enabling remote work for your team.",
    category: 'security',
    thumbnail: "https://images.unsplash.com/photo-1593642532744-d377ab507dc8?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://www.cyber.gc.ca/sites/default/files/ITSAP10016-security-tips-organizations-remote-workers-e.pdf"
  }
];

const templates = [
  {
    title: "IT Security Checklist for SMBs",
    description: "A comprehensive checklist to help assess and improve your organization's IT security posture.",
    category: 'security',
    thumbnail: "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://safetyculture.com/checklists/cyber-security/"
  },
  {
    title: "Vendor Risk Assessment Template",
    description: "Evaluate the security and compliance posture of your third-party vendors.",
    category: 'security',
    thumbnail: "https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://www.manageengine.com/network-monitoring/vendor-assessment-template.pdf"
  },
  {
    title: "Incident Response Plan Template",
    description: "Ensure your business is prepared to respond effectively to security breaches.",
    category: 'security',
    thumbnail: "https://images.unsplash.com/photo-1504639725590-34d0984388bd?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://www.secureworld.io/free-template-incident-response-plan"
  },
  {
    title: "BYOD Policy Template",
    description: "Create a Bring Your Own Device policy to manage personal devices accessing company data.",
    category: 'security',
    thumbnail: "https://images.unsplash.com/photo-1526738549149-8e07eca6c147?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://www.betterteam.com/bring-your-own-device-policy-template"
  }
];

const articles = [
  {
    title: "Top 10 Cybersecurity Tips for Small Businesses",
    description: "Practical tips to strengthen your SMB's cybersecurity posture without breaking the budget.",
    category: 'cybersecurity',
    thumbnail: "https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://www.kaspersky.com/resource-center/preemptive-safety/small-business-cyber-security"
  },
  {
    title: "How to Secure Your IT Systems on a Budget",
    description: "A guide for SMBs to implement effective security measures with limited resources.",
    category: 'security',
    thumbnail: "https://images.unsplash.com/photo-1550751827-4bd374c3f58b?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://levelblue.com/blogs/security-essentials/25-essential-cybersecurity-tips-and-best-practices-for-your-business"
  },
  {
    title: "Understanding Ransomware: Prevention and Recovery",
    description: "Insights into how ransomware works and how SMBs can protect themselves.",
    category: 'security',
    thumbnail: "https://images.unsplash.com/photo-1614064641938-3bbee52942c7?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://www.cisa.gov/stopransomware"
  },
  {
    title: "9 Free Cybersecurity White Papers for Small Businesses",
    description: "Explore free white papers designed to help small businesses understand and improve their cybersecurity posture.",
    category: 'cybersecurity',
    thumbnail: "https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://smallbusiness.chamber.com/resources/cybersecurity-whitepapers"
  },
  {
    title: "Cybersecurity for SMBs: Trends and Best Practices",
    description: "Learn the latest trends and best practices for small businesses to stay ahead of evolving threats.",
    category: 'cybersecurity',
    thumbnail: "https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://www.trendmicro.com/smb-cybersecurity"
  },
  {
    title: "Building a Culture of Security in SMBs",
    description: "Tips for small business owners to promote security awareness and culture within their organization.",
    category: 'business',
    thumbnail: "https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://www.nortonlifelock.com/blog/smb-culture-security"
  },
  {
    title: "Cyber Hygiene Practices for SMBs",
    description: "A guide to improving cybersecurity hygiene to reduce risks and protect critical data.",
    category: 'security',
    thumbnail: "https://images.unsplash.com/photo-1510915228340-29c85a43dcfe?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://www.cyber.gc.ca/en/cyber-hygiene"
  }
];

const tools = [
  {
    title: "Network Assessment Tool",
    description: "Analyze your network's performance and identify potential security risks.",
    category: 'infrastructure',
    thumbnail: "https://images.unsplash.com/photo-1558494949-ef010cbdcc31?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://obkio.com/blog/network-assessment-tools/"
  },
  {
    title: "Password Policy Generator",
    description: "Create a secure password policy for your employees to follow.",
    category: 'security',
    thumbnail: "https://images.unsplash.com/photo-1633265486064-086b219458ec?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://security.org/password-generator/"
  },
  {
    title: "Cyber Risk Assessment Tool",
    description: "Evaluate your SMB's cybersecurity risks and get actionable recommendations.",
    category: 'security',
    thumbnail: "https://images.unsplash.com/photo-1562813733-b31f71025d54?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://www.nist.gov/cyberframework/assessments"
  },
  {
    title: "Phishing Simulation Tool",
    description: "Test your team's ability to recognize phishing attacks with this free tool.",
    category: 'security',
    thumbnail: "https://images.unsplash.com/photo-1590247813693-5541d1c609fd?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://knowbe4.com/phishing-security-test"
  },
  {
    title: "Endpoint Security Solution",
    description: "Find and remediate endpoint vulnerabilities across your business network.",
    category: 'security',
    thumbnail: "https://images.unsplash.com/photo-1526374965328-7f61d4dc18c5?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://www.sophos.com/en-us/products/endpoint"
  },
  {
    title: "Data Backup and Recovery Tool",
    description: "A reliable solution for ensuring your SMB's data is always recoverable.",
    category: 'infrastructure',
    thumbnail: "https://images.unsplash.com/photo-1600267175161-cfaa711b4a81?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://www.acronis.com/en-us/products/backup/"
  },
  {
    title: "Free Cybersecurity Audit Tool",
    description: "Quickly assess your organization's overall cybersecurity health.",
    category: 'security',
    thumbnail: "https://images.unsplash.com/photo-1614064642639-e398cf05badb?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://cybersecurityaudit.com/free-audit"
  },
  {
    title: "Firewall Configuration Tester",
    description: "Ensure your firewall is optimally configured to block threats.",
    category: 'security',
    thumbnail: "https://images.unsplash.com/photo-1555066931-4365d14bab8c?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://www.grc.com/shieldsup"
  },
  {
    title: "Vulnerability Scanner for SMBs",
    description: "Scan your business network for vulnerabilities and address them proactively.",
    category: 'security',
    thumbnail: "https://images.unsplash.com/photo-1563986768494-4dee2763ff3f?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://www.tenable.com/products/nessus"
  },
  {
    title: "Mobile Device Management (MDM) Tool",
    description: "Secure and manage mobile devices accessing your SMB's network.",
    category: 'infrastructure',
    thumbnail: "https://images.unsplash.com/photo-1512941937669-90a1b58e7e9c?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    link: "https://www.vmware.com/products/workspace-one.html"
  }
];

export default Resources;