import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyAi-4GqU2yW2qSfO0phzY6oKYv5WFiuSUA",
  authDomain: "nit-solutions-ltd.firebaseapp.com",
  projectId: "nit-solutions-ltd",
  storageBucket: "nit-solutions-ltd.firebasestorage.app",
  messagingSenderId: "1009434437063",
  appId: "1:1009434437063:web:caaf6d7c6ba8e32ac925e2",
  measurementId: "G-HQ48EKL63C"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);