import { StrictMode } from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import App from './App.tsx';
import './index.css';

const rootElement = document.getElementById('root')!;
const app = (
  <StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </StrictMode>
);

if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, app);
} else {
  createRoot(rootElement).render(app);
}

// Expose window variables for crawlers
declare global {
  interface Window {
    __PRELOADED_STATE__: any;
  }
}

// Add preloaded state for crawlers
window.__PRELOADED_STATE__ = {
  meta: {
    title: 'NIT Solutions - IT Services & Support in Calgary',
    description: 'Your trusted IT partner in Calgary. We provide comprehensive IT solutions including managed services, cloud solutions, cybersecurity, and 24/7 support for businesses.',
    url: 'https://nit-solutions.netlify.app'
  }
};