import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ChatWidget = () => {
  const location = useLocation();
  const isTicketsPage = location.pathname === '/tickets';

  useEffect(() => {
    if (!isTicketsPage) {
      // Add chat widget script
      const script = document.createElement('script');
      script.innerHTML = `
        window.zenCloneConfig = {
          widgetId: "7jcKamoZpSXZMVRh0E7zTsKh8pm2",
          domain: "https://helporium.netlify.app"
        };
      `;
      document.body.appendChild(script);

      // Add chat widget source
      const widgetScript = document.createElement('script');
      widgetScript.src = 'https://helporium.netlify.app/widget.js';
      widgetScript.async = true;
      document.body.appendChild(widgetScript);

      return () => {
        document.body.removeChild(script);
        if (document.body.contains(widgetScript)) {
          document.body.removeChild(widgetScript);
        }
      };
    }
  }, [isTicketsPage]);

  return null;
};