import { doc, getDoc, setDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

const defaultDashboardConfig = {
  components: [
    { 
      id: 'backup',
      type: 'status',
      title: "Backup Status",
      description: "Monitor and track system backup operations",
      enabled: true,
      position: 0,
      locations: ['cards', 'charts', 'sections']
    },
    { 
      id: 'network',
      type: 'status',
      title: "Network Status",
      description: "Track network performance and uptime",
      enabled: true,
      position: 1,
      locations: ['cards', 'charts']
    },
    { 
      id: 'antivirus',
      type: 'status',
      title: "Antivirus Status",
      description: "Monitor antivirus protection and threats",
      enabled: true,
      position: 2,
      locations: ['cards']
    },
    { 
      id: 'firewall',
      type: 'status',
      title: "Firewall Status",
      description: "Track firewall activity and connections",
      enabled: true,
      position: 3,
      locations: ['cards']
    },
    {
      id: 'systemHealth',
      type: 'metrics',
      title: "System Health",
      description: "Overview of system performance metrics",
      enabled: true,
      position: 4,
      locations: ['sections']
    },
    {
      id: 'recentAlerts',
      type: 'alerts',
      title: "Recent Alerts",
      description: "Latest system and security alerts",
      enabled: true,
      position: 5,
      locations: ['sections']
    }
  ],
  charts: [
    { componentId: 'network', type: 'line', enabled: true, position: 0 },
    { componentId: 'backup', type: 'doughnut', enabled: true, position: 1 }
  ],
  cards: [
    { componentId: 'backup', enabled: true, position: 0 },
    { componentId: 'network', enabled: true, position: 1 },
    { componentId: 'antivirus', enabled: true, position: 2 },
    { componentId: 'firewall', enabled: true, position: 3 }
  ],
  sections: [
    { componentId: 'backup', enabled: true, position: 0 },
    { componentId: 'systemHealth', enabled: true, position: 1 },
    { componentId: 'recentAlerts', enabled: true, position: 2 }
  ]
};

export interface UserData {
  email: string;
  role: 'admin' | 'user';
  createdAt: string;
  disabled: boolean;
  style?: {
    backgroundColor?: string;
    textColor?: string;
  };
  dashboardConfig?: {
    charts: {
      id: string;
      type: 'line' | 'bar' | 'doughnut';
      enabled: boolean;
      position: number;
      title?: string;
      chartType?: string;
      refreshInterval?: number;
      showLegend?: boolean;
      showGrid?: boolean;
      chartData?: {
        labels: string[];
        datasets: Array<{
          label?: string;
          data: number[];
          backgroundColor?: string | string[];
          borderColor?: string | string[];
          borderWidth?: number;
        }>;
      };
    }[];
    cards: {
      id: string;
      enabled: boolean;
      position: number;
      title?: string;
      alertThreshold?: number;
      showIcon?: boolean;
      showTimestamp?: boolean;
    }[];
    additionalSections?: {
      id: string;
      enabled: boolean;
      position: number;
      title?: string;
      showTimestamp?: boolean;
      showIcons?: boolean;
      refreshInterval?: number;
    }[];
  };
}

export const updateUserDashboard = async (uid: string, dashboardConfig: UserData['dashboardConfig']): Promise<void> => {
  try {
    await setDoc(doc(db, 'users', uid), { 
      dashboardConfig,
      updatedAt: new Date().toISOString()
    }, { merge: true });
  } catch (error) {
    console.error('Error updating dashboard config:', error);
    throw error;
  }
};
export const createUserDocument = async (uid: string, email: string, role?: 'admin' | 'user'): Promise<UserData> => {
  try {
    // Check if this is the first user (dan.bressers@gmail.com)
    const isAdmin = role === 'admin' || email === 'dan.bressers@gmail.com';
    
    const userData: UserData = {
      email,
      role: isAdmin ? 'admin' : 'user',
      createdAt: new Date().toISOString(),
      disabled: false,
      dashboardConfig: defaultDashboardConfig
    };

    await setDoc(doc(db, 'users', uid), userData);
    return userData;
  } catch (error) {
    console.error('Error creating user document:', error);
    throw error;
  }
};

export const getUserData = async (uid: string): Promise<UserData | null> => {
  try {
    const userDoc = await getDoc(doc(db, 'users', uid));
    if (userDoc.exists()) {
      return userDoc.data() as UserData;
    }
    return null;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const getAllUsers = async (): Promise<{ id: string; data: UserData }[]> => {
  try {
    const usersSnapshot = await getDocs(collection(db, 'users'));
    return usersSnapshot.docs.map(doc => ({
      id: doc.id,
      data: doc.data() as UserData
    }));
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const updateUserStatus = async (uid: string, disabled: boolean): Promise<void> => {
  try {
    await setDoc(doc(db, 'users', uid), { 
      disabled,
      updatedAt: new Date().toISOString()
    }, { merge: true });
  } catch (error) {
    console.error('Error updating user status:', error);
    throw error;
  }
};

export const updateUserRole = async (uid: string, role: 'admin' | 'user'): Promise<void> => {
  try {
    await setDoc(doc(db, 'users', uid), { 
      role,
      updatedAt: new Date().toISOString()
    }, { merge: true });
  } catch (error) {
    console.error('Error updating user role:', error);
    throw error;
  }
};

export const updateUserStyle = async (uid: string, style: UserData['style']): Promise<void> => {
  try {
    await setDoc(doc(db, 'users', uid), { 
      style,
      updatedAt: new Date().toISOString()
    }, { merge: true });
  } catch (error) {
    console.error('Error updating user style:', error);
    throw error;
  }
};