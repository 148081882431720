import React, { useEffect, useState } from 'react';
import { X } from 'lucide-react';

export const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Check if user has already made a choice
    const consent = localStorage.getItem('cookie-consent');
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookie-consent', 'accepted');
    setShowBanner(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookie-consent', 'declined');
    setShowBanner(false);
    
    // Remove Google Analytics cookies if declined
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      const [name] = cookie.split('=');
      if (name.trim().startsWith('_ga')) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.${window.location.hostname}`;
      }
    }
  };

  if (!showBanner) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 shadow-lg z-50">
      <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
        <div className="flex flex-col sm:flex-row items-center gap-4">
          <div className="flex items-center gap-4 order-2 sm:order-1">
            <button
              onClick={handleDecline}
              className="px-4 py-2 text-gray-700 hover:text-gray-900"
            >
              Decline
            </button>
            <button
              onClick={handleAccept}
              className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-150"
            >
              Accept
            </button>
          </div>
          <div className="flex-1 order-1 sm:order-2">
            <p className="text-gray-700">
              We use cookies to enhance your browsing experience and analyze site traffic. 
              By clicking "Accept", you consent to our use of cookies.
              {' '}
              <a 
                href="/privacy" 
                className="text-blue-600 hover:text-blue-700 underline"
                onClick={(e) => e.stopPropagation()}
              >
                Learn more
              </a>
            </p>
          </div>
          <button
            onClick={handleDecline}
            className="text-gray-400 hover:text-gray-500 sm:hidden order-3"
            aria-label="Close"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};