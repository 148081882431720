import React from 'react';
import { Link } from 'react-router-dom';
import { Monitor, Mail, Phone, MapPin } from 'lucide-react';
import { encodeEmail } from '../../utils/email';

const contactEmail = encodeEmail('info@nit-solutions.com');

export const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white min-h-[400px]">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 h-full">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="min-h-[100px]">
            <div className="flex items-center">
              <Monitor className="h-8 w-8 text-blue-400" />
              <span className="ml-2 text-xl font-bold">NIT Solutions</span>
            </div>
            <p className="mt-2 text-gray-400 min-h-[48px]">
              Your trusted IT partner in Calgary and surrounding areas.
            </p>
          </div>
          
          <div className="min-h-[200px]">
            <h3 className="text-sm font-semibold uppercase tracking-wider">Contact</h3>
            <ul className="mt-4 space-y-4">
              <li className="flex items-center">
                <Phone className="h-5 w-5 text-blue-400 mr-2" />
                <a href="tel:+15878886993" className="hover:text-blue-400">
                  (587) 888-6993
                </a>
              </li>
              <li className="flex items-center">
                <Mail className="h-5 w-5 text-blue-400 mr-2" />
                <a 
                  href="#" 
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = `mailto:${decodeEmail(contactEmail)}`;
                  }}
                  className="hover:text-blue-400"
                  dangerouslySetInnerHTML={{ __html: contactEmail }}
                >
                </a>
              </li>
              <li className="flex items-center">
                <MapPin className="h-5 w-5 text-blue-400 mr-2" />
                <span>4620 Manilla Rd SE, Calgary, AB T2G 4B7</span>
              </li>
            </ul>
          </div>

          <div className="min-h-[200px]">
            <h3 className="text-sm font-semibold uppercase tracking-wider">Quick Links</h3>
            <ul className="mt-4 space-y-4">
              <li>
                <Link to="/services" className="hover:text-blue-400">Services</Link>
              </li>
              <li>
                <Link to="/about" className="hover:text-blue-400">About Us</Link>
              </li>
              <li>
                <Link to="/resources" className="hover:text-blue-400">Resources</Link>
              </li>
              <li>
                <Link to="/contact" className="hover:text-blue-400">Contact</Link>
              </li>
              <li>
                <Link to="/faq" className="hover:text-blue-400">FAQ</Link>
              </li>
            </ul>
          </div>

          <div className="min-h-[200px]">
            <h3 className="text-sm font-semibold uppercase tracking-wider">Legal</h3>
            <ul className="mt-4 space-y-4">
              <li>
                <Link to="/privacy" className="hover:text-blue-400">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms" className="hover:text-blue-400">Terms of Service</Link>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="mt-8 border-t border-gray-700 pt-8 min-h-[48px]">
          <p className="text-center text-gray-400">
            © {new Date().getFullYear()} NIT Solutions Ltd. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};